<template>
  <el-dialog
    :visible.sync="selfDialogShow"
    width="25%"
    top="25vh"
    :title="$t('configurationManagement.addToConfigurationManagement')"
    :close-on-click-modal="false"
    :show-close="true"
    custom-class="create_dashboard"
  >
    <el-select v-model="selectValue" style="width: 100%">
      <el-option
        v-for="item in configurationOptions"
        :key="item.configItemId"
        :label="item.name"
        :value="item.configItemId"
      >
      </el-option>
    </el-select>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="hideDialog">{{
        $t("btn.cancelBtn")
      }}</el-button>
      <el-button type="primary" class="button-confirm" @click="createChart">{{
        $t("btn.confirmBtn")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getAllconfiguration,
  addKnowledgeBaseViewConfigItem,
  addFileConfigItem,
} from "@/network/configurationManagement/index.js";
export default {
  components: {},
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
    isBatch: {
      type: Boolean,
      default: false,
    },
    documentId: {
      type: String,
      default: "",
    },
    fileId: {
      type: String,
      default: "",
    },
    fileIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      configurationOptions: [],
      selectValue: "",
    };
  },
  computed: {
    selfDialogShow: {
      get() {
        return this.dialogShow;
      },
      set(val) {
        return val;
      },
    },
  },
  mounted() {
    this.getAllconfiguration();
  },
  methods: {
    hideDialog() {
      this.$emit("hideAddToConfigDialog");
    },
    createChart() {
      if (this.documentId) {
        const params = {
          projectId: this.get_pid(),
          configItemId: this.selectValue,
          data: [this.documentId],
        };
        addKnowledgeBaseViewConfigItem(params).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("quick.AddedSuccessfully"),
          });
          this.$emit("hideAddToConfigDialog");
        });
      } else {
        console.log(this.fileIds);
        const params = {
          projectId: this.get_pid(),
          configItemId: this.selectValue,
          data: [],
        };
        if (this.isBatch) {
          params.data = [];
          this.fileIds.forEach((item) => {
            params.data.push(item.file_id);
          });
        } else {
          params.data = [this.fileId];
        }
        addFileConfigItem(params).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("quick.AddedSuccessfully"),
          });
          this.$emit("hideAddToConfigDialog");
        });
      }
    },
    getAllconfiguration() {
      getAllconfiguration(this.get_pid())
        .then((res) => {
          this.configurationOptions = [...res];
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style></style>
