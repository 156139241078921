/* eslint-disable comma-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-extra-semi */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-var */
/* eslint-disable indent */


/**
 * 使用方法：
 * 1、先建一个img，src绑定base64数据。
 * 2、Drawio.editElement(传入上面的img dom)，即可自动打开一个iframe，编辑完之后点击保存。
 * 3、window.addEventListener("message",fun)，构造监听函数，保存时JSON.parse(e.data).event是export，
 * 此时JSON.parse(e.data).data里有编辑后的base64数据，保存即可。同时手动更新img标签的数据。若没有刷新，可以绑定key，改变key值即可重新渲染。
 */

function Drawio(config, ui, done, initialized, urlParams) {
	this.config = (config != null) ? config : this.config;
	this.ui = (ui != null) ? ui : this.ui;
	this.done = (done != null) ? done : this.done;
	this.initialized = (initialized != null) ? initialized : this.initialized;
	this.urlParams = urlParams;

	var self = this;

	this.handleMessageEvent = function (evt) {
		if (self.frame != null && evt.source == self.frame.contentWindow &&
			evt.data.length > 0) {
			try {
				var msg = JSON.parse(evt.data);

				if (msg != null) {
					self.handleMessage(msg);
				}
			}
			catch (e) {
				console.error(e);
			}
		}
	};
};
Drawio.prototype.ele = null
Drawio.editElement = function (elt, config, ui, done, urlParams) {
	Drawio.prototype.ele = elt
	if (!elt.diagramEditorStarting) {
		elt.diagramEditorStarting = true;

		return new Drawio(config, ui, done, function () {
			delete elt.diagramEditorStarting;
		}, urlParams).editElement(elt);
	}
};
Drawio.prototype.config = {
	"simpleLabels": true
};
Drawio.prototype.drawDomain = process.env.NODE_ENV === 'intl' ? 'embed.diagrams.net/' : 'embed-drawio.ytdevops.com/';
Drawio.prototype.ui = 'min';
Drawio.prototype.xml = null;
Drawio.prototype.format = 'xml';
Drawio.prototype.libraries = true;
Drawio.prototype.frameStyle = 'position:absolute;border:0;width:100%;height:100%;';
Drawio.prototype.editElement = function (elem) {
	var src = this.getElementData(elem);
	this.startElement = elem;
	var fmt = this.format;
	
	if (src.substring(0, 15) === 'data:image/png;') {
		fmt = 'xmlpng';
	}
	else if (src.substring(0, 19) === 'data:image/svg+xml;' ||
		elem.nodeName.toLowerCase() == 'svg') {
		fmt = 'xmlsvg';
	}

	this.startEditing(src, fmt);

	return this;
};
Drawio.prototype.getElementData = function (elem) {
	var name = elem.nodeName.toLowerCase();

	return elem.getAttribute((name == 'svg') ? 'content' :
		((name == 'img') ? 'src' : 'data'));
};

Drawio.prototype.setElementData = function (elem, data) {
	var name = elem.nodeName.toLowerCase();

	if (name == 'svg') {
		elem.outerHTML = atob(data.substring(data.indexOf(',') + 1));
	}
	else {
		elem.setAttribute((name == 'img') ? 'src' : 'data', data);
	}

	return elem;
};
Drawio.prototype.startEditing = function (data, format, title) {
	if (this.frame == null) {
		window.addEventListener('message', this.handleMessageEvent);
		this.format = (format != null) ? format : this.format;
		this.title = (title != null) ? title : this.title;
		this.data = data;

		this.frame = this.createFrame(
			this.getFrame(data),
			this.getFrameStyle());
		document.body.appendChild(this.frame);
		this.setWaiting(true);
	}
};
Drawio.prototype.setWaiting = function (waiting) {
	if (this.startElement != null) {
		var elt = this.startElement;
		var name = elt.nodeName.toLowerCase();

		if (name == 'svg' || name == 'object') {
			elt = elt.parentNode;
		}

		if (elt != null) {
			if (waiting) {
				this.frame.style.pointerEvents = 'none';
				this.previousCursor = elt.style.cursor;
				elt.style.cursor = 'wait';
			}
			else {
				elt.style.cursor = this.previousCursor;
				this.frame.style.pointerEvents = '';
			}
		}
	}
};
Drawio.prototype.setActive = function (active) {
	if (active) {
		this.previousOverflow = document.body.style.overflow;
		document.body.style.overflow = 'hidden';
	}
	else {
		document.body.style.overflow = this.previousOverflow;
	}
};
Drawio.prototype.stopEditing = function () {
	if (this.frame != null) {
		window.removeEventListener('message', this.handleMessageEvent);
		document.body.removeChild(this.frame);
		this.setActive(false);
		this.frame = null;
	}
};
Drawio.prototype.postMessage = function (msg) {
	if (this.frame != null) {
		this.frame.contentWindow.postMessage(JSON.stringify(msg), '*');
	}
};
Drawio.prototype.getData = function () {
	return this.data;
};
Drawio.prototype.getTitle = function () {
	return this.title;
};
Drawio.prototype.getFrameStyle = function () {
	return this.frameStyle + ';left:' +
		document.body.scrollLeft + 'px;top:' +
		document.body.scrollTop + 'px;';
};
Drawio.prototype.getFrame = function (e) {
	// var url = this.drawDomain + '?offline=0&proto=json&spin=1&simpleLabels=1&format=png&zoom=150&lang=' + (localStorage.getItem('lang') || 'zh');
	var url = this.drawDomain + '?proto=json&spin=1&ui=min&libraries=1&splash=0&embed=1&simpleLabels=1&format=png&zoom=150&lang=' + (localStorage.getItem('lang') || 'zh');

	if (this.ui != null) {
		// url += '&ui=' + this.ui;
	}

	if (this.libraries != null) {
		// url += '&libraries=1';
	}

	if (this.config != null) {
		// url += '&configure=1';
	}

	if (this.urlParams != null) {
		// url += '&' + this.urlParams.join('&');
	}

	if (e != null) {
		// url = 'embed.' + url
	}
	return process.env.NODE_ENV === "privatization" ? 'http://localhost:8082/?proto=json&spin=1&ui=min&libraries=1&splash=0&embed=1&simpleLabels=1&format=png&zoom=150' : 'https://' + url
};
Drawio.prototype.createFrame = function (url, style) {
	var frame = document.createElement('iframe');
	frame.setAttribute('frameborder', '0');
	frame.setAttribute('style', style);
	frame.setAttribute('src', url);
	frame.style.zIndex = 999999;
	return frame;
};
Drawio.prototype.setStatus = function (messageKey, modified) {
	this.postMessage({ action: 'status', messageKey: messageKey, modified: modified });
};
Drawio.prototype.handleMessage = function (msg) {
	if (msg.event == 'configure') {
		this.configureEditor();
	}
	else if (msg.event == 'init') {
		this.initializeEditor();
	}
	else if (msg.event == 'autosave') {
		this.save(msg.xml, true, this.startElement);
	}
	else if (msg.event == 'export') {
		this.setElementData(this.startElement, msg.data);
		this.stopEditing();
		this.xml = null;
	}
	else if (msg.event == 'save') {
		this.save(msg.xml, false, this.startElement);
		this.xml = msg.xml;

		if (msg.exit) {
			msg.event = 'exit';
		}
		else {
			this.setStatus('allChangesSaved', false);
		}
	}
	if (msg.event == 'exit') {
		if (this.format != 'xml') {
			if (this.xml != null) {
				this.postMessage({
					action: 'export', format: 'xmlpng',
					xml: this.xml, spinKey: 'export', originelement: Drawio.prototype.ele.id,
					scale: 5
				});
			}
			else {
				this.stopEditing(msg);
			}
		}
		else {
			if (msg.modified == null || msg.modified) {
				this.save(msg.xml, false, this.startElement);
			}

			this.stopEditing(msg);
		}
	}
};
Drawio.prototype.configureEditor = function () {
	this.postMessage({ action: 'configure', config: this.config });
};

Drawio.prototype.initializeEditor = function () {
	this.postMessage({
		action: 'load', autosave: 1, saveAndExit: '1',
		modified: 'unsavedChanges', xml: this.getData(),
		title: this.getTitle(), config: this.config
	});
	this.setWaiting(false);
	this.setActive(true);
	this.initialized();
};
Drawio.prototype.save = function (data, draft, elt) {
	this.done(data, draft, elt);
};
Drawio.prototype.done = function () {
};
Drawio.prototype.initialized = function () {
};
export {
	Drawio
}